<template>
  <v-card flat>
     <v-card-text class="mt-15">
      <div class="d-flex flex-column justify-center mt-15">
        <h3 class="mt-15 text--disabled text-center"> Hi {{ capitalise(getSession.username) }}, Your account is not yet activated to begin serving !!</h3>
        <span class="text--disabled text-center" > Contact your Chief Protocol Officer to help you out. </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'limited',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getDrawerStatus', 'getSession', 'isAuthenticated']),
  },
  methods: {
    capitalise(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>
